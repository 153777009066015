<template>
  <div class="home">
    <Navigation />
    <div>
      <div>
        <div style="max-width: 950px" class="mx-auto my-10 px-5">
          <h1>Manage Your Team</h1>
          <form
            id="team-form"
            name="team-form"
            @submit="updateTeam"
            style="marging: 10px"
          >
            <div>
              <div class="form-group">
                <label for="Name-8" class="form-label">Team Name</label>
                <input
                  type="text"
                  class="form-input w-input"
                  maxlength="256"
                  name="Name"
                  v-model="team.name"
                />
              </div>
            </div>
            <div class="w-layout-grid form-grid">
              <div class="form-group">
                <label for="Name-8" class="form-label">Address 1</label>
                <input
                  type="text"
                  class="form-input w-input"
                  maxlength="256"
                  name="address-1"
                  v-model="team.address1"
                  required=""
                />
              </div>
              <div class="form-group">
                <label for="Email-9" class="form-label">Address 2</label>
                <input
                  type="text"
                  class="form-input w-input"
                  maxlength="256"
                  name="Address 2"
                  v-model="team.address2"
                />
              </div>
            </div>
            <div class="w-layout-grid form-grid-halves">
              <div class="form-group">
                <label for="Company-2" class="form-label">City</label
                ><input
                  type="text"
                  class="form-input w-input"
                  maxlength="256"
                  name="City"
                  v-model="team.city"
                />
              </div>
              <div class="form-group">
                <label for="Phone-3" class="form-label">State</label
                ><input
                  type="text"
                  maxlength="2"
                  name="State"
                  v-model="team.state"
                  class="form-input w-input"
                />
              </div>
            </div>
            <div class="w-layout-grid form-grid-halves">
              <div class="form-group">
                <label for="Company-2" class="form-label">Zip</label
                ><input
                  type="text"
                  class="form-input w-input"
                  maxlength="9"
                  name="Zip"
                  v-model="team.zipCode"
                />
              </div>
              <div class="form-group">
                <label for="Phone-3" class="form-label">CountryCode</label
                ><input
                  type="text"
                  maxlength="2"
                  name="CountryCode"
                  v-model="team.countryCode"
                  class="form-input w-input"
                />
              </div>
            </div>
            <div class="form-group">
              <textarea
                placeholder="Universal Hashtags"
                maxlength="5000"
                id="Message-4"
                v-model="team.hashTags"
                class="form-input text-area-input w-input"
              ></textarea>
            </div>
            <v-btn
              color="primary"
              type="submit"
              :disabled="loading"
              :loading="loading"
              >Save</v-btn
            >
          </form>
        </div>
        <div class="content-width-medium mb-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
//import ProfileService from "@/services/profileService.js";
import { teamService } from "@/services";
import Navigation from "@/components/Navigation.vue";
export default {
  components: {
    Navigation,
  },
  data: () => ({
    loading: false,
    team: {
      name: null,
      hashTags: null,
      adminEmail: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zipCode: null,
      countryCode: null,
    },
    selectedItem: "Sample",
  }),
  created() {},
  mounted() {
    //var userId = this.$auth.user.sub;
    //console.log({ teamService, user: this.$auth });
    teamService.getCurrent().then((data) => {
      console.log(data);
      this.team = data;
    });
    this.email = this.$auth.user.email;
  },
  methods: {
    async updateTeam(e) {
      e.preventDefault();
      this.loading = true;
      try {
        await teamService.update(this.team);
      } catch (error) {}
      this.loading = false;
    },
    button() {},
  },
};
</script>
<style scoped>
.logout {
  margin-left: 15px;
  background-color: #f19953;
  border-color: #f19953;
}
.cancel {
  margin-left: 15px;
  background-color: #ed6a5e;
  border-color: #ed6a5e;
}
.button-row {
  margin-bottom: 25px;
}
.team-save {
  margin-bottom: 15px;
}
</style>
